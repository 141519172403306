function setupCookieJar() {
  // Find UI buttons
  const noneButton = document.querySelector('[data-role="cookie-jar-none"]')
  const allButton = document.querySelector('[data-role="cookie-jar-all"]')
  const selectionButton = document.querySelector('[data-role="cookie-jar-selection"]')

  // Add listeners
  noneButton?.addEventListener('click', cookieJarNone);
  allButton?.addEventListener('click', cookieJarAll);
  selectionButton?.addEventListener('click', cookieJarSelection);
}

async function cookieJarNone() {
  await apiPost({ accept: 'none'})
}

async function cookieJarAll() {
  await apiPost({ accept: 'all'})
}

async function cookieJarSelection() {
  let selection = []
  for(const option of document.querySelectorAll('[data-role="cookie-jar-option"]')) {
    selection.push(option.name);
  }
  await apiPost({ accept: 'selection', selection});
}

async function apiPost(payload) {
  const response = await fetch('/cookie-jar', {
    method: 'post',
    body: JSON.stringify(payload)
  })
  const data = await response.json()
  if(data.snippet) {
    const fragment = document.createRange()
      .createContextualFragment(data.snippet)
    document.body.appendChild(fragment)
    document.dispatchEvent(new Event('DOMContentLoaded'))
  }

  document.querySelector('[data-role="cookie-jar-pop-up"]')?.remove()
}

async function injectCode() {
}

document.addEventListener('DOMContentLoaded', e => {
  setupCookieJar();
});
